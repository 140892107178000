@import "../../assets/theme/styles";
.hero_container {
    width: 100%;
    display: flex;
    min-height: 400px;
    justify-content: center;
    align-content: center;
    background-color: #333333;
    padding-left: 10px;
    padding-right: 10px;
}

.hero_wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.hero_slogan {
    h1 {
        font-size: 4rem;
        text-align: left;
        max-width: 500px;
        line-height: 5rem;
        color: white;
    }
}

.hero_img {
    .logo_0 {
        width: 300px;
    }
}

.contact_hero {
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/images/dmc.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    h2 {
        font-family: $font_poppins;
        color: #db3838;
        border-bottom: 4px solid white;
        font-size: 3rem;
    }

}