@import "../../assets/theme/styles";
.contact_cards   {
  display: flex;
  justify-content: center;
  margin-top: -100px;
  flex-wrap: wrap;

}

.contact_card {
  box-shadow: 0 0 1.8rem rgba(0,0,0,.15);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  min-width: 310px;
  max-width: 320px;
  margin: 20px;
  border-radius: 10px;
  background-color: white;
  font-family: $font_poppins;
  span {
    font-size: 2.2rem;
  }
  p {
    text-align: center;
    font-size: 1.1rem;
    
  }
}
.team_cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;



}
.team_card {
  max-width: 315px;
  margin: 10px;
  box-shadow: 0 0 1.8rem rgba(0,0,0,.15);

  .team_info {
    padding: 10px;
    text-align: center;
    margin-top: 0;
    font-family: $font_poppins;

    h4 {
      font-size: 1.4rem;
      line-height: 5px;
    }
    p {
      color: #9b8a8a;
    }

    div {
      display: flex;
      justify-content: center;

      a {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 1.2rem;
        color: black;
      }
    }
  }
  img {
    width: 280px;
    height: 250px;
  }
}

.team_heading {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  text-align: center;
  font-family: $font_poppins;
  font-weight: bold;
  width: max-content;
  margin: 100px auto 50px;
  border-bottom: #db3838 1px solid;
}