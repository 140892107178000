@import "../../assets/theme/styles.scss";

.main_container {
    border-radius: 10px;
    margin-top: 100px;
}

.main_wrapper {
    padding : 10px;
}

.yt_card {
    background-color: white;
    //box-shadow: 0 0 1.8rem rgba(0,0,0,.15);
}

.cla-btn {
    padding:8px 16px;
    background-color: #db3838;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-family: $font_mukta;

    &:hover {
        opacity: 0.6;
    }
}