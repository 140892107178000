@import "../../assets/theme/styles";
.footer_container {
  background-color: #333333;
  margin-top: 20px;

}
.footer_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.footer_logo {
  color: #db3838;
  font-size: 2.3rem;

  span {
     font-size: 1.3rem;
     border-radius: 50%;
    color: white;
    background-color: #db3838;
  }
}
.footer_social_links {
  a {
    text-decoration: none;
    padding-right: 16px;
    font-family: $font_padauk;
    color: white;
    font-size: 1.4rem;
    transition: all 300ms ease;
  }

}
.fb:hover {
  color: skyblue;
}
.tw:hover {
  color: deepskyblue;
}
.yt:hover {
  color: #db3838;
}
.footer_links  {

  a {
    text-decoration: none;
    padding-right: 16px;
    font-family: $font_padauk;
    color: white;
    font-size: 1.2rem;
    transition: all 300ms ease;

    &:hover {
      text-decoration: #db3838 underline;
    }
  }
}
.copyright_section  {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 5px;

  font-family: $font_mukta;
}

.footer_active {
  text-decoration: underline #db3838;
}
