@import "../../assets/theme/styles.scss";

.header_container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  box-shadow: 0px 1px 10px #999;
  z-index: 333;

}

.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.header_logo {
  img {
    width: 100px;
    height: 100px;
  }
}

.header_nav {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 735px) {
    display: none;
  }
}

.header_link {
  text-decoration: none;
  font-family: $font_poppins;
  color: #9b8a8a;
  cursor: pointer;
  padding: 8px 16px;
  //border-radius: 20px;

  margin-left: 10px;
}

.active_link {
  color:  #db3838;
  //background-color: #db3838;
  //border: 1px #db3838 solid;
  // border-bottom: 1px solid #db3838;
  background-color: #eeeeee;
}

.menu_btn {
  display: none;

  @media screen and (max-width: 735px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #9b8a8a;
    color: #9b8a8a;
    cursor: pointer;
  }
}

// Drawer ---->

.drawer_container {
    background-color: #eeeeee;
    position: absolute;
    top: 70px;
    right: 0;
    width: 0;
    display: none;
    overflow: hidden;
    transition: width 300ms linear ;
    @media screen and (max-width : 735px){
      display: block;
    }


}
.drawer_nav {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.drawer_link {
  font-family: $font_padauk;
  font-size: 1.3rem;
  text-align: left;
  background-color: white;
  margin-bottom: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 80%;
  margin-left: auto;
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  overflow: hidden;
}

.drawer_active {
  background-color: #db3838;
  color: white;
}
