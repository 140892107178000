@import "../../assets/theme/styles.scss";
.perception_container {
  box-shadow: 0 0 1.8rem rgba(0,0,0,.15);
  font-size: 1.2rem;
  background-color: white;
  margin-top: -80px;
  border-radius: 10px;
  padding: 20px;
  font-family: $font_mukta;
}
.perception_para {
  li {
    list-style: none;
    margin-bottom: 15px;
  }
}